import Api from "../apis/Api";
import ActionModalMixin from "./ActionModalMixin";

export default {
  data: function () {
    return {
      phpmyadminSsoLoading: false,
      phpmyadminSsoUrl: "",
    };
  },
  mixins: [ActionModalMixin],
  methods: {
    async phpmyadminSsoLogin(hostingAccountId, serverType) {
      if (this.phpmyadminSsoLoading) {
        return;
      }
      this.phpmyadminSsoLoading = true;
      if (serverType == 'plesk') {
        let databases = [];
        await Api.get(`/server-accounts/${hostingAccountId}/mysql/databases`)
          .then((response) => {
            for (let db of response.data.data) {
              databases.push({
                value: db.id,
                label: db.database,
              })
            }
          })
          .catch((error) => {
            this.$store.dispatch("addAlert", {
              success: false,
              errorMessage: Api.getErrorMessage(error),
            });
          });
        if (databases.length === 0) {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: this.$t('message.emptyTable.databases.database.description'),
          });
          this.phpmyadminSsoLoading = false;
          return;
        }
        if (databases.length === 1) {
          await this.phpmyadminSsoLoginToDatabase(databases[0].value, hostingAccountId);
          this.phpmyadminSsoLoading = false;
          return;
        }
        this.showChooseDatabaseModal(databases, hostingAccountId);
        this.phpmyadminSsoLoading = false;
        return;
      }

      Api.post(`/server-accounts/${hostingAccountId}/mysql/phpmyadmin-sso-url`).then((response) => {
        window.open(response.data.data.url);
      }).catch((error) => {
        this.$store.dispatch("addAlert", {
          success: false,
          errorMessage: Api.getErrorMessage(error),
        });
      }).finally(() => {
        this.phpmyadminSsoLoading = false;
      });
    },
    phpmyadminSsoLoginToDatabase(id, hostingAccountId){
      return Api.post(`/server-accounts/${hostingAccountId}/mysql/phpmyadmin-sso-url`,{
        database_id: id,
      }).then((response) => {
        window.open(response.data.data.url);
      }).catch((error) => {
        this.$store.dispatch("addAlert", {
          success: false,
          errorMessage: Api.getErrorMessage(error),
        });
      });
    },
    showChooseDatabaseModal(options, hostingAccountId) {
      this.resetModal();
      this.modalOptions.title = "PHPMyAdmin";
      this.modalOptions.color = 'info'
      this.modalOptions.persistent = true;
      this.modalOptions.formValues = {}

      this.modalOptions.buttons.unshift({
        label: this.$t("button.login.phpmyadmin"),
        color: 'primary',
        onclick: async modal => {
          this.modalOptions.submitting = true;
          await this.phpmyadminSsoLoginToDatabase(modal.formValues.database, hostingAccountId);
          this.modalOptions.submitting = false;
          this.modalOptions.open = false;
        }
      });

      this.modalOptions.formValues.database = options[0].value;
      this.modalOptions.formFields = [
        {
          label: this.$t("Database"),
          name: "database",
          type: "select",
          options: options,
        },
      ];
      this.modalOptions.submitting = false;
      this.modalOptions.open = true;
    },
  },
}
